import { getDateParts } from './GetDayParts'
import { transitionDay } from './Constants'

const setMonthHour = (today) => {
  const { day, month, year } = getDateParts(today)
  let startOfTheYear, endOfTheYear
  if (month === 11 && day >= transitionDay) {
    startOfTheYear = +new Date(year, 11, transitionDay)
    endOfTheYear = +new Date(year + 1, 11, transitionDay)
  } else {
    startOfTheYear = +new Date(year - 1, 11, transitionDay)
    endOfTheYear = +new Date(year, 11, transitionDay)
  }
  const monthPeriod = +((+endOfTheYear - startOfTheYear) / 12)
  const monthStage = +Math.trunc((+today - startOfTheYear) / monthPeriod)
  return monthStage * 30
}

export { setMonthHour }
