import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { sendFetchApi } from '../api'
import Spinner from '../components/Spinner'

const Retreat = () => {
  const { master } = useParams()
  const [retreat, setRetreatData] = useState()

  useEffect(() => {
    document.title = `Посещение обители Владыки`
    ;(async () => {
      try {
        const data = await sendFetchApi(`retreat/${master}`)
        document.title = `Посещение обители ${data.retreatName}`
        setRetreatData(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [master])

  const printRetreat = (fieldName) =>
    retreat && retreat[fieldName] && retreat[fieldName].length
      ? retreat[fieldName].map((el, index) =>
          index === 0 ? (
            <h3 className='alignCenter'>{el}</h3>
          ) : (
            <div key={el} className='retreatText'>
              {el}
            </div>
          )
        )
      : ''

  const printCall = (fieldName) =>
    retreat && retreat[fieldName] && retreat[fieldName].length
      ? retreat[fieldName].map((el, index) => (
          <div key={el} className='retreatText'>
            {el}
          </div>
        ))
      : ''

  return (
    <Spinner data={retreat}>
      <div className='callCommon'>
        <div className='retreat-block'>
          <h3>Призыв:</h3>
          {printCall('call')}
        </div>
        <div className='retreat-block'>
          <h3>Ключевая нота:</h3>
          {retreat?.keynoteInfo}
          {retreat?.keynoteUrl && (
            <div>
              <audio
                controls
                src={`https://www.amasters.info/audio/${retreat.keynoteUrl}`}
              >
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </div>
          )}
        </div>
        <div className='retreat-block'>{printRetreat('retreatInfo')}</div>
      </div>
    </Spinner>
  )
}

export default Retreat
