import useStyles from './TagPage.style'
import { useRef, useState } from 'react'

const TagsPage = () => {
  const { body, flexContainer, label, textarea, outputArea, button } =
    useStyles()
  const inputEl = useRef()
  const [buttonState, setButtonState] = useState('COPY')

  const changeHandler = ({ target }) => {
    console.log(target.value)

    const enteredText = target.value || ''
    const parsedTextArray = enteredText
      .trim()
      .toLowerCase()
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item)
      .sort((a, b) => b.length - a.length)
    const parcedTAGS = Array.from(new Set(parsedTextArray))
    const stringTAGS = parcedTAGS.join(' , ')
    const counterTAGS = parcedTAGS.join('').length + (parcedTAGS.length - 1)
    console.log(parcedTAGS.join(''))
    console.log(parcedTAGS.join('').length)

    inputEl.current.value = stringTAGS
    setButtonState(counterTAGS)
  }

  const buttonHandler = () => {
    inputEl.current.select()
    document.execCommand('copy')
  }

  return (
    <div style={body}>
      <div style={flexContainer}>
        <label style={label} htmlFor='story'>
          Enter Tags Here:
        </label>
        <textarea
          style={textarea}
          rows='10'
          cols='100'
          onChange={changeHandler}
        ></textarea>
        <label style={label} htmlFor='story'>
          Filtered Tags:
        </label>
        <textarea
          style={{ ...textarea, ...outputArea }}
          rows='10'
          cols='100'
          readOnly
          ref={inputEl}
        ></textarea>

        <button style={button} onClick={buttonHandler}>
          {buttonState}
        </button>
        {/* <label for='story'>any case:</label>
      <textarea id='inputArea2' name='story' rows='5' cols='50'></textarea>
      <label for='story'>UPPER CASE:</label>
      <textarea
        id='outputArea2'
        name='story'
        rows='5'
        cols='50'
        readonly
      ></textarea>
      <div></div>
      <button id='button2'>COPY</button>
      <hr />
      <ul>
        <li>PREAMBLE</li>
        <li>PERSONAL CALLS</li>
        <li>BODY OF THE DECREE</li>
        <li>SEALING OF THE DECREE</li>
      </ul> */}
      </div>
    </div>
  )
}

export default TagsPage
