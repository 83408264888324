import { useState, useEffect } from 'react'
import { sendFetchApi } from '../api/GET.method'
import Spinner from '../components/Spinner'

const MainPage = () => {
  const [list, setList] = useState([])
  const fetchList = async () => {
    const responce = await sendFetchApi('call/all')
    console.log(responce)
    setList(responce)
  }
  useEffect(() => fetchList(), [])
  return (
    <div className='callCommon'>
      <h3>Указы Санату Кумаре:</h3>
      <Spinner data={list?.length}>
        {list.map(({ title, listNumber }) => (
          <div key={listNumber}>
            {listNumber}. <a href={listNumber}>{title}</a>
          </div>
        ))}
      </Spinner>
    </div>
  )
}

export default MainPage
