import { sendFetchApiPOST } from '../../../api/POST.method'
const setMoonStage = async (today) => {
  try {
    return await sendFetchApiPOST(`clock/moon`, { today })
  } catch (error) {
    console.log(error)
  }
}

export { setMoonStage }
