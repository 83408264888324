import { getDateParts } from './GetDayParts'
import { twoWeeekPeriod, dayPeriod } from './Constants'

const setStageFoo = (today) => {
  const { year } = getDateParts(today)
  const transitionPoint = new Date(year, 5, 27)
  let startPoint
  if (today >= transitionPoint) {
    startPoint = transitionPoint
  } else {
    startPoint = new Date(year - 1, 5, 27)
  }
  let stageValue = Math.trunc(
    ((+today - startPoint) % twoWeeekPeriod) / dayPeriod
  )

  if (stageValue === 12) {
    stageValue = 0
  } else if (stageValue === 13) {
    stageValue = 6
  }

  return stageValue * 30
}

export { setStageFoo }
