export default function useStyles() {
  return {
    body: {
      margin: 0,
      padding: 0,
      backgroundColor: 'darkcyan',
      height: '100vh',
      fontFamily: 'Georgia, serif',
    },

    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
    },

    label: {
      fontSize: 'larger',
      letterSpacing: '1px',
      fontWeight: 'bold',
      margin: '10px',
    },

    textarea: {
      fontSize: 'larger',
      letterSpacing: '1px',
      lineHeight: '1.5',
      borderRadius: '10px',
      border: '1px solid #ccc',
      boxShadow: '1px 1px 1px #999',
      padding: '10px',
      margin: '10px',
    },

    outputArea: {
      backgroundColor: 'blanchedalmond',
      fontWeight: 'bold',
    },

    button: {
      margin: '10px',
      lineHeight: '2em',
      backgroundColor: 'pink',
      borderRadius: '10px',
      fontWeight: 'bolder',
      fontSize: 'xx-large',
    },
  }
}
