const Statlist = ({ statTable = [], title = '', maxRes = 0 }) => {
  return (
    <table className='statTable'>
      <thead>
        <tr>
          <th colSpan={4} className='header'>
            <h2>{title}</h2>
          </th>
        </tr>
      </thead>
      <tbody>
        {statTable.map(({ title, todayShows, listNumber }) => (
          <tr key={listNumber}>
            <td className='tableColSmall'>{listNumber}</td>
            <td className='tableNamesCol'>{title}</td>
            <td className='tableGraph'>
              <progress max={maxRes} value={todayShows} />
            </td>
            <td className='tableColSmall'>{todayShows}</td>
          </tr>
        ))}
        <tr>
          <td colSpan={4} className='Footer'></td>
        </tr>
      </tbody>
    </table>
  )
}

export default Statlist
