import { adressString } from './getAdress'

export const sendFetchApi = async (queryString) => {
  try {
    const request = await fetch(adressString(queryString), {
      credentials: 'include',
    })
    return await request.json()
  } catch (error) {
    console.log(error)
  }
}
